<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
  <g fill="none" fill-rule="evenodd">
    <path :fill="color" d="M36.797619,19 C44.3530842,19 51.3333747,22.3273184 56.078192,27.8521462 L56.3973929,28.2307692 L56.4285714,28.2307692 C63.6483188,28.2307692 70.3005881,31.2684366 74.9920558,36.2814888 L75.3161686,36.6344283 L75.4313333,36.7657692 L75.4947783,36.7371627 C77.5259526,35.8364259 79.731818,35.2978223 82.0203987,35.1789394 L82.5499591,35.1589818 L82.9880952,35.1538462 L98,35.1538462 L98,72.0769231 L82.9880952,72.0769231 C80.5301458,72.0769231 78.1512405,71.5925673 75.9620802,70.6932596 L75.4313333,70.465 L75.3161238,70.5960863 C70.8173401,75.5892063 64.4107065,78.7158914 57.4010139,78.9815917 L56.8993136,78.9956912 L56.4285714,79 L26.4047619,79 C12.3740993,79 1,67.6349206 1,53.6153846 C1,39.736044 12.1477548,28.4583363 25.9846477,28.23417 L26.4047619,28.2307692 L28.7142857,28.2307692 L28.7142857,19 L36.797619,19 Z"/>
    <path fill="#000" d="M37.4333333,26 C44.2440045,26 50.1904225,29.7003072 53.3718439,35.2001779 L57.2666667,35.2 C65.0344544,35.2 71.6780105,40.0134079 74.377356,46.8202446 C76.4686499,43.958706 79.8505963,42.1 83.6666667,42.1 L92,42.1 L92,65.1 L83.6666667,65.1 C79.8505963,65.1 76.4686499,63.241294 74.3767668,60.3798212 C71.6780105,67.1865921 65.0344544,72 57.2666667,72 L26.4,72 C16.2379606,72 8,63.7620394 8,53.6 C8,43.4379606 16.2379606,35.2 26.4,35.2 L35.999,35.2 L36,26 L37.4333333,26 Z M24.4,49 L21.9333333,49 C19.3928235,49 17.3333333,51.0594902 17.3333333,53.6 C17.3333333,56.1405098 19.3928235,58.2 21.9333333,58.2 L21.9333333,58.2 L24.4,58.2 C26.9405098,58.2 29,56.1405098 29,53.6 C29,51.0594902 26.9405098,49 24.4,49 L24.4,49 Z M43.0666667,49 L40.6,49 C38.0594902,49 36,51.0594902 36,53.6 C36,56.1405098 38.0594902,58.2 40.6,58.2 L40.6,58.2 L43.0666667,58.2 C45.6071765,58.2 47.6666667,56.1405098 47.6666667,53.6 C47.6666667,51.0594902 45.6071765,49 43.0666667,49 L43.0666667,49 Z M61.7333333,49 L59.2666667,49 C56.7261568,49 54.6666667,51.0594902 54.6666667,53.6 C54.6666667,56.1405098 56.7261568,58.2 59.2666667,58.2 L59.2666667,58.2 L61.7333333,58.2 C64.2738432,58.2 66.3333333,56.1405098 66.3333333,53.6 C66.3333333,51.0594902 64.2738432,49 61.7333333,49 L61.7333333,49 Z"/>
  </g>
</svg>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                required: false,
                default: '#4BD7C0'
            }
        }
    }
</script>