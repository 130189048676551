<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
    <g fill="none" fill-rule="evenodd" transform="translate(5)">
      <path :fill="color" d="M85.1869942,30 C85.1869942,13.5 67.1488868,0 45.1023112,0 C23.0557355,0 5.01762814,13.5 5.01762814,30 C-2.49824993,38.5 -1.49613285,52 7.52292083,61 C9.52715498,63 12.0324477,64.5 14.5377404,66.5 C22.0536184,83.5 33.0769063,100 45.1023112,100 C57.6287746,100 68.1510039,83.5 75.666882,66 C78.1721747,64.5 80.6774674,62.5 82.6817015,60.5 C91.7007552,52 92.2018137,38.5 85.1869942,30 Z"/>
      <path fill="#000" fill-rule="nonzero" d="M77,34 C77,20.8 62.6,10 45,10 C27.4,10 13,20.8 13,34 C7,40.8 7.8,51.6 15,58.8 C16.6,60.4 18.6,61.6 20.6,63.2 C26.6,76.8 35.4,90 45,90 C55,90 63.4,76.8 69.4,62.8 C71.4,61.6 73.4,60 75,58.4 C82.2,51.6 82.6,40.8 77,34 Z M20.6,52.8 C16.6,48.8 15.8,42 19.4,38.4 C25,33.6 31.4,37.2 33.8,39.6 C37.4,43.2 40.6,50.4 41,54.8 C41.4,56.4 40.6,58.4 40.2,59.2 C39.8,59.6 38.6,60 37,60 C31.8,60 24.2,56.4 20.6,52.8 Z M49,78 L41,78 C38.6,78 37,76.4 37,74 C37,71.6 38.6,70 41,70 L49,70 C51.4,70 53,71.6 53,74 C53,76.4 51.4,78 49,78 Z M69.4,52.8 C66.2,56.4 58.6,60 53,60 C51.4,60 50.6,59.6 49.8,59.2 C49.4,58.8 48.6,56.8 49,55.2 C49,54 49.8,47.6 56.2,40 C58.2,37.2 65,34 70.6,38.4 C74.2,42 73.4,48.8 69.4,52.8 Z"/>
    </g>
  </svg>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                required: false,
                default: '#D20073'
            }
        }
    }
</script>