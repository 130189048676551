<template>
    <div class="sticker-layer">
        <component
            v-for="(sticker, index) in stickers"
            :is="sticker.type"
            class="sticker" 
            :color="sticker.color"
            :style="sticker.style"
            :key="'sticker_'+index"
        >
        </component>
    </div>
</template>

<script>
    import {debounce} from '@/js/helpers'

    // SVG's
    import alien from '@/js/svg/stickers/alien'
    import cross from '@/js/svg/stickers/cross'
    import explosion from '@/js/svg/stickers/explosion'
    import genderqueer from '@/js/svg/stickers/genderqueer'
    import globe from '@/js/svg/stickers/globe'
    import mountain from '@/js/svg/stickers/mountain'
    import peach from '@/js/svg/stickers/peach'
    import submarine from '@/js/svg/stickers/submarine'
    import teddy from '@/js/svg/stickers/teddy'

    export default {
        name: 'Stickers',
        components: {
            alien,
            cross,
            explosion,
            genderqueer,
            globe,
            mountain,
            peach,
            submarine,
            teddy
        },
        data() {
            return {
                idle: false,
                maxStickers: 6,
                stickers: [],


                stickerTypes: [
                    'alien',
                    'cross',
                    'explosion',
                    'genderqueer',
                    'globe',
                    'mountain',
                    'peach',
                    'submarine',
                    'teddy'
                ],

                stickerColors: [
                    /* red */         "#D20000",
                    /* orange */      "#FF9D22",
                    /* yellow */      "#FFF330",
                    /* green-light */ "#65D328",
                    /* green-dark */  "#00AC62",
                    /* turquise */    "#4BD7C0",
                    /* blue */        "#4D3BFF",
                    /* purple */      "#5700BF",
                    /* pink */        "#D20073",
                ],

                prevColor: null
            };
        },
        methods: {
            generateSticker() {
                for (let index = 0; index < this.maxStickers; index++) {
                    const x = Math.floor(Math.random() * (this.$el.offsetWidth-50)),
                          y = Math.floor(Math.random() * (this.$el.offsetHeight))-25;

                    let color = this.stickerColors[Math.floor(Math.random() * this.stickerColors.length)]

                    // while (color == this.prevColor) {
                    //     let color = this.stickerColors[Math.floor(Math.random() * this.stickerColors.length)]
                    // }

                    const sticker = (
                        {
                            type: this.stickerTypes[Math.floor(Math.random() * this.stickerTypes.length)],
                            color: color,
                            style: `transform: translate(${x}px, ${y}px) rotate(${Math.floor(Math.random()*100) - 50}deg);`
                        }
                    );

                    this.prevColor = color;
                    this.stickers.push(sticker);
                }
            }
        },
        mounted() {
            const wMax = Math.ceil(this.$el.offsetWidth/130);
            const hMax = Math.ceil(this.$el.offsetHeight/70);
            
            this.maxStickers = Math.min(wMax, hMax);

            this.generateSticker();
        }
    };
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .sticker-layer {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        z-index: 3;

        .sticker {
            width:      10vw;
            height:     10vw;

            min-width:  60px;
            min-height: 60px;
            max-width:  125px;
            max-height: 125px;

            position: absolute;

            @each $name, $color in $colors {
                &.#{$name} .inherit-color  {
                    fill: $color;
                }
            }
        }
        
    }
</style>