<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
    <g fill="none" fill-rule="evenodd">
      <polygon :fill="color" points="49.999 8 99 82 1 82"/>
      <path fill="#000" d="M37.9196725,5.66392493 L25.9383431,23.6631227 L31.7897116,18.9461106 L30.8476051,23.6072317 L34.4521261,18.3733438 L35.7735826,22.214115 L37.6786901,15.7436974 L39.6163001,21.5831476 L40.8982893,16.2785877 L43.8035435,25.4710335 L44.1383187,19.3262615 L52.2425709,27.1020332 L37.9196725,5.66392493 L37.9196725,5.66392493 Z M37.9995357,0 L76,57 L0,57 L37.9995357,0 Z" transform="translate(12 18)"/>
    </g>
  </svg>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                required: false,
                default: '#4D3BFF'
            }
        }
    }
</script>