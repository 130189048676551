<template>
 <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
  <g fill="none" fill-rule="evenodd" transform="translate(1 10.746)">
    <polygon :fill="color" points="56.469 15.154 78.421 .477 71.316 26.882 98.114 32.103 71.27 47.451 70.921 47.654 77.143 66.768 59.05 60.537 50.079 78.207 41.609 60.654 12.842 76.155 26.113 47.227 .9 36.426 25.561 28.891 15.615 7.713 40.936 17.982 49.39 2.215"/>
    <path fill="#000" fill-rule="nonzero" d="M65.2709301,45.5405951 L68.9165518,43.4146997 L84.8818606,34.2858548 L66.9051743,30.7843801 L65.5223523,30.5342747 L65.8994855,29.0336427 L70.6765071,11.2761637 L55.3397537,21.5304826 L54.8369093,21.9056406 L49.4313323,12.0264797 L43.3971999,23.2812199 L43.1457777,23.9064833 L41.2601113,23.1561673 L24.9176692,16.5283758 L32.0832015,31.7848014 L14.4836485,37.1620662 L31.7060682,44.5401737 L32.3346237,44.7902791 L31.454646,46.7911218 L22.9062917,65.4239695 L41.0086891,55.6698613 L43.6486221,54.1692293 L44.1514664,55.1696506 L50.1855989,67.6749176 L56.4711536,55.2947033 L56.7225757,54.7944926 L69.7965294,59.2963887 L65.5223523,46.1658584 L65.2709301,45.5405951 Z M57.8539756,51.5431232 L55.0883315,50.5427018 L53.4540873,53.7940713 L50.31131,59.9216521 L47.2942438,53.6690186 L45.2828663,49.4172278 L39.3744449,52.5435446 L30.3232462,57.4205987 L34.5974234,48.0416485 L36.9859341,42.914489 L33.0888903,41.1637516 L24.5405359,37.4121716 L33.4660235,34.6610128 L36.9859341,33.5355388 L35.6031121,30.5342747 L31.8317793,22.5309039 L40.0030004,25.907326 L44.7800219,27.9081687 L46.5399772,24.6567993 L49.5570434,19.0294292 L52.5741097,24.6567993 L53.5797984,26.5325893 L57.2254201,24.0315359 L64.8937968,18.9043765 L62.505286,27.783116 L61.122464,32.9102755 L66.1509077,33.9106968 L75.2021064,35.6614342 L67.2823076,40.288383 L61.122464,43.7898577 L62.2538638,47.0412271 L64.3909524,53.6690186 L57.8539756,51.5431232 Z"/>
    <polygon fill="#000" fill-rule="nonzero" points="56.84 42.446 65.327 37.495 56.713 35.886 58.993 27.347 52.153 31.926 49.239 26.728 45.946 32.669 38.219 29.574 41.386 36.01 34.039 38.238 41.132 41.332 37.332 49.5 46.453 44.674 50.126 52.223 53.166 46.283 58.74 48.263"/>
  </g>
</svg>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                required: false,
                default: '#00AC62'
            }
        }
    }
</script>