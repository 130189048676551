<script>
    export default {
        // mixins: [TranslationHelper],
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        components: {
            HeaderComponent: () => import('@/js/components/contentBlocks/HeaderComponent.vue' /* webpackChunkName: 'build/js/partials/HeaderComponent' */),
            CalendarComponent: () => import('@/js/components/contentBlocks/CalendarComponent.vue' /* webpackChunkName: 'build/js/partials/CalendarComponent' */),
            ShowComponent: () => import('@/js/components/contentBlocks/ShowComponent.vue' /* webpackChunkName: 'build/js/partials/ShowComponent' */),
            VideoComponent: () => import('@/js/components/contentBlocks/VideoComponent.vue' /* webpackChunkName: 'build/js/partials/VideoComponent' */),
            ImageComponent: () => import('@/js/components/contentBlocks/ImageComponent.vue' /* webpackChunkName: 'build/js/partials/ImageComponent' */),
            NewsComponent: () => import('@/js/components/contentBlocks/NewsComponent.vue' /* webpackChunkName: 'build/js/partials/NewsComponent' */),
            TextComponent: () => import('@/js/components/contentBlocks/TextComponent.vue' /* webpackChunkName: 'build/js/partials/TextComponent' */),
            TextBigComponent: () => import('@/js/components/contentBlocks/TextBigComponent.vue' /* webpackChunkName: 'build/js/partials/TextBigComponent' */),
            CreditsComponent: () => import('@/js/components/contentBlocks/CreditsComponent.vue' /* webpackChunkName: 'build/js/partials/CreditsComponent' */),
            PeopleComponent: () => import('@/js/components/contentBlocks/PeopleComponent.vue' /* webpackChunkName: 'build/js/partials/PeopleComponent' */),
        },
        computed: {
            title() {
                return this.content.title ? this.content.title : '';
            },
            slug() {
                return this.content.slug ? this.content.slug : '';
            },
            pageClass() {
                return this.content.slug ? 'page-'+this.content.slug : '';
            }
        },
        mounted() {
           
        }
    }
</script>

<style lang="scss">
    
</style>