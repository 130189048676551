<template>
    <div class="page-wrapper show-overview">
       <ShowsList :shows="content.shows"/>
    </div>
</template>

<script>
    import DefaultPage from '@/js/mixins/DefaultPage'
    import ShowsList from '@/js/components/ShowsList'
    
    // this.$store.getters.getProjectEndpoint+currentTitle+'.json'
    export default {
        mixins: [DefaultPage],
        components: {
            ShowsList
        },
        data: function() {
            return {
              
            }
        },
        computed: {
         
        },
        methods: {
          
        },
        mounted() {
        
        },
        created() {
           
        }

    }
</script>

<style lang="scss">
    .page-wrapper.show-overview {
        flex: 1;
        display: flex;
    }
</style>

