<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
  <g fill="none" fill-rule="evenodd" transform="translate(15 6)">
    <polygon :fill="color" points="19.26 0 50.34 0 50.34 16.704 69.42 16.704 69.42 47.064 50.34 47.064 50.34 88.8 19.26 88.8 19.26 47.064 .18 47.064 .18 16.704 19.26 16.704"/>
    <path fill="#000" fill-rule="nonzero" d="M44.3400015,22.7040007 L44.3400015,6 L25.2600008,6 L25.2600008,22.7040007 L6.18000001,22.7040007 L6.18000001,41.0640014 L25.2600008,41.0640014 L25.2600008,82.8000031 L44.3400015,82.8000031 L44.3400015,41.0640014 L63.4200023,41.0640014 L63.4200023,22.7040007 L44.3400015,22.7040007 Z M58.6200021,36.2640012 L39.5400013,36.2640012 L39.5400013,78.0000029 L30.060001,78.0000029 L30.060001,36.2640012 L10.9800002,36.2640012 L10.9800002,27.5040009 L30.060001,27.5040009 L30.060001,10.8000002 L39.5400013,10.8000002 L39.5400013,27.5040009 L58.6200021,27.5040009 L58.6200021,36.2640012 Z"/>
  </g>
</svg>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                required: false,
                default: '#D20000'
            }
        }
    }
</script>