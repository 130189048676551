<template>
    <div class="show-sticker-layer">
        <img class="sticker"  :src="stickerUrl" ref="refImage" @load="refImageLoaded">
        <img
            v-for="(sticker, index) in stickers"
            :src="stickerUrl"
            class="sticker position" 
            :style="sticker.style"
            :key="'sticker_'+index"
        >
    </div>
</template>

<script>
    export default {
        props: {
            stickerUrl: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                maxStickers: 25,
                stickers: [],
                offset: 0,
            };
        },
        methods: {
            refImageLoaded() {
                this.offset = this.$refs['refImage'].offsetWidth/2;

                this.generateSticker();
            },
            generateSticker() {
                // Set the height to the full height of the document
                this.$el.style.height = document.body.clientHeight+'px';

                for (let index = 0; index < this.maxStickers; index++) {

                    
                    const x = Math.floor(Math.random() * (this.$el.offsetWidth))-this.offset,
                          y = Math.floor(Math.random() * (this.$el.offsetHeight)),
                          rotation = Math.floor(Math.random()*100) - 50;

                    const sticker = (
                        {
                            style: `transform: translate(${x}px, ${y}px) rotate(${rotation}deg);`
                        }
                    );

                    const timeout = index*70

                    setTimeout(() => {
                       this.stickers.push(sticker); 
                    }, timeout);
                }
            }
        }
    };
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .show-sticker-layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        z-index: 2;
        overflow: hidden;

        .sticker {
            width: 40vw;

            &.position {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        
    }
</style>