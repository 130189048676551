<template>
    <div class="shows-list">
       <div
            v-for="(show, index) in shows"
            class="item"
            :style="{'--i': index}"
            :key="'shows_'+index"
        >
            <router-link
                :to="{ path: '/'+show.slug }"
                @mouseover.native="onMouseover(show.sticker)"
                @mouseleave.native="onMouseleave"
            >{{ show.title }}</router-link>
        </div>
        <ShowsListSticker v-if="currenHoveredShowSticker" :stickerUrl="currenHoveredShowSticker"/>
    </div>
</template>

<script>
    import ShowsListSticker from '@/js/components/ShowsListSticker'

    export default {
        data() {
            return {
                currenHoveredShowSticker: null
            }
        },
        props: {
            shows: {
                type: Array,
                required: false
            }
        },
        computed: {
            addStickers: {
               get() {
                   return this.$store.state.addStickers;
               },
               set(addStickers) {
                   this.$store.commit('setAddStickers', addStickers)
               }
            },
        },
        components: {
            ShowsListSticker
        },
        methods: {
            onMouseover(sticker) {
                this.addStickers = false;

                if(sticker && sticker.url) {
                    this.currenHoveredShowSticker = sticker.url;
                    return;
                }
                
                this.currenHoveredShowSticker = null; 
            },
            onMouseleave() {
                this.addStickers = true;
                this.currenHoveredShowSticker = null;
            }
        }
    };
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .shows-list {
        flex: 1;
        padding: 4rem 4rem 0 4rem;
        background-color: $black;
        font-family: 'Oswald', sans-serif;
        text-align: center;

        @include font-big;
        text-transform: uppercase;

        .item {
            position: relative;

            a {
                position: relative;
                color: $white;
                text-decoration: none;
                
                &:hover {
                    z-index: 3;
                }
            }

        }
    }
</style>