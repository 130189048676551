<template>
    <div class="main-nav-wrapper" :class="[theme, {'open':open}]">
        <NewsTicker />
        <nav class="main-nav">
            
            <router-link class="logo" to="/">
                <teddySVG :color='teddyColor' />Teddy's Last Ride
            </router-link>

            <div class="menu-items">
                <router-link
                    v-for="(menuItem, index) in menuItems"
                    :to="{ path: '/'+menuItem.uri }"
                    :key="'menuitem_'+index"
                >{{ menuItem.title }}</router-link>
            </div>

            <LangToggle />
            
        </nav>
        <nav class="main-nav-mobile" :class="{'has-news-ticker':hasNewsTicker}">
            
            <router-link class="logo" to="/" @click.native="closeMobileMenu">
                <teddySVG :color='teddyColor' />Teddy's Last Ride
            </router-link>

            <div class="menu-toggle" @click="toggleMobileMenu">
                <span>menu</span>
                <span>close</span>
            </div>

            <div class="dropdown">
                <div class="menu-items">
                    <router-link
                        v-for="(menuItem, index) in menuItems"
                        :to="{ path: '/'+menuItem.uri }"
                        :key="'menuitem_'+index"
                        @click.native="closeMobileMenu"
                    >{{ menuItem.title }}</router-link>
                </div>

                <LangToggle @click.native="closeMobileMenu"/>
                <Stickers :key="'toggle_'+toggles"/>
            </div>
            
        </nav>
    </div>
</template>

<script>
    import LangToggle from '@/js/components/LangToggle'
    import NewsTicker from '@/js/components/NewsTicker'
    import teddySVG from '@/js/svg/teddy'
    import Stickers from '@/js/components/Stickers'

    export default {
        components: {
            LangToggle,
            NewsTicker,
            teddySVG,
            Stickers
        },
        data() {
            return {
                open: false,
                toggles: 0,
                closeTimeout: null
            };
        },
        computed: {
            theme() {
                return this.$store.state.menuTheme;
            },
            teddyColor: function (params) {
                return this.$store.state.menuTheme == 'white' ? '#fff':'#000';
            },
            'menuItems': function() {
                return this.$store.state.websiteData ? this.$store.state.websiteData.menu : null;
            },
            hasNewsTicker() {
                return this.$store.state.isHome && this.$store.state.websiteData.newsTicker;
            }
        },
        methods: {
            toggleMobileMenu() {
                clearTimeout(this.closeTimeout);
                this.open = !this.open;

                if(this.open) {
                    this.toggles++;
                }
            },
            closeMobileMenu() {
                clearTimeout(this.closeTimeout);
                this.closeTimeout = setTimeout(() => {
                    this.open = false;
                }, 600);
            }
        },
        mounted() {
           
        }
    };
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .main-nav-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        min-height: 2rem;
        z-index: 5;
        user-select: none;

        .main-nav, .main-nav-mobile {
            display: none;
            position: relative;
            padding: $defaultPadding;
            color: $white;
            justify-content: space-between;

            @include defaultHref;

            .logo {
                position: relative;

                svg { 
                    position: relative;
                    margin-right: 0.2em;
                    top: 0.1em;
                }
            }

            .menu-items {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                a {
                    margin-right: 1em;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .lang-toggle {
                text-align: right;
            }
        }

        .main-nav-mobile {
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            transition: top .25s cubic-bezier(0.65, 0, 0.35, 1);

            .logo {
                z-index: 2;
            }

            .menu-toggle {
                z-index: 2;

                span {
                    display: none;

                    &:first-child {
                        display: block;
                    }
                }
            }

            .dropdown {
                opacity: 0;
                height: 100vh;
                width: 100vw;
                position: absolute;
                pointer-events: none;
                top: 0;
                left: 0;
                background: $black;
                transition: opacity .25s cubic-bezier(0.65, 0, 0.35, 1);
                
                .menu-items {
                    @include font-big;
                    font-family: 'Oswald', sans-serif;
                    display: flex;
                    flex-flow: column;
                    text-align: center;
                    opacity: 0;
                    transition-delay: .4s;
                    transition: opacity .5s cubic-bezier(0.65, 0, 0.35, 1);

                    a {
                        margin: 0;
                    }
                }

                .lang-toggle {
                    position: absolute;
                    top: auto;
                    bottom: $defaultPadding;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        &.black {
            .main-nav, .main-nav-mobile {
                color: $black;
            }
        }

        &.open {
            .main-nav-mobile {
                color: $white;

                .logo svg g {
                    fill: $white;
                }

                .dropdown {
                    opacity: 1;
                    pointer-events: all;

                    .menu-items {
                        opacity: 1;
                        
                    }
                }

                .menu-toggle {
                    z-index: 2;

                    span {
                        display: none;

                        &:last-child {
                            display: block;
                        }
                    }
                }

                &.has-news-ticker {
                    top: -2rem;
                }
            }
        }

        @include tablet {
            .main-nav {
                display: flex;
            }

            .main-nav-mobile {
                display: none;
            }
        }
    }
</style>