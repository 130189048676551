<template>
    <div class="page-wrapper single" :class="pageClass">
        <component 
                v-for="(component, index) in content.components" 
                :is="component.name"
                :content="component.data" 
                :key="component.name+'_'+index"
        />
    </div>
</template>

<script>
    import DefaultPage from '../mixins/DefaultPage.vue'
    
    export default {
        mixins: [DefaultPage],
        data: function() {
            return {
              
            }
        },
        computed: {
            
        },
        methods: {
          
        },
        mounted() {
        
        },
        created() {
           
        }

    }
</script>

<style lang="scss">
  
</style>

