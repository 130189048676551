<template>
    <div v-if="isHome && message" class="news-ticker" @click="onClick">
        <div class="body">
            <div v-for="index in 10" :key="index" class="text">
                <span>{{ message }}</span> <newsAlertSVG class="alert-svg"/>
            </div>
        </div>
    </div>
</template>

<script>
    import newsAlertSVG from '@/js/svg/newsAlert'

    export default {
         components: {
            newsAlertSVG
        },
        computed: {
            isHome() {
                return this.$store.state.isHome;
            },
            message() {
                return this.$store.state.websiteData ? this.$store.state.websiteData.newsTicker : null;
            }
        },
        methods: {
            onClick() {
                const el = document.getElementsByClassName('news')[0];
                el.scrollIntoView({behavior: "smooth", block: "center"});
            }
        }
    };
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .news-ticker {
        height: 2rem;
        overflow: hidden;
        background-color: $red;
        transition: all .25s cubic-bezier(0.65, 0, 0.35, 1);
        cursor: pointer;

        .body {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
            white-space: nowrap;
            height: 100%;
            animation: marquee 4s linear infinite;

            .text {
                min-width: 24em;
                overflow: hidden;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;

                > span {
                    flex: 1;
                    text-align: center;
                }

                .alert-svg {
                    margin: 0 $defaultPadding;
                    height: 100%;
                }
            }
        }

        @keyframes marquee {
            0% {
                transform: translate3d(0, 0, 0);
            }

            100% {
                transform: translate3d(24em, 0, 0);
            }
        }
    }
</style>