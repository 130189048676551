<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
  <g fill="none" fill-rule="evenodd">
    <path :fill="color" d="M59.1170299,2.20212766 L59.117,11.989 L67.3258972,6.65944452 L77.8012828,22.7901239 L67.157,29.702 L77.8012828,36.614199 L68.365,51.144 L68.551187,51.3220323 C73.4804976,56.1020929 76.6227716,62.7068785 76.9687281,70.0017158 L76.9876381,70.4890482 L76.9969264,70.8908339 L77,71.2978723 C77,86.5848321 64.7004636,99 49.5,99 C34.2995364,99 22,86.5848321 22,71.2978723 C22,63.6018793 25.1368233,56.563947 30.2553962,51.5070173 L30.632,51.141 L21.1986568,36.614636 L31.842,29.701 L21.1986568,22.7902168 L31.6742228,6.65925983 L39.882,11.988 L39.8833745,2.20212766 L59.1170299,2.20212766 Z"/>
    <path fill="#000" fill-rule="nonzero" d="M53.1170213,8.20212766 L53.117,23.039 L65.5614462,14.9588991 L69.5013882,21.0258777 L56.141,29.702 L69.5013882,38.3783776 L65.5614462,44.4453562 L53.117,36.364 L53.1174795,49.9016182 C63.1558693,51.6184104 70.8271689,60.358376 70.9971196,70.938988 L71,71.2978723 C71,83.2836265 61.3741221,93 49.5,93 C37.6258779,93 28,83.2836265 28,71.2978723 C28,60.5565215 35.7308531,51.6377835 45.8825205,49.9016182 L45.882,36.364 L33.4385538,44.4453562 L29.4986118,38.3783776 L42.858,29.701 L29.4986118,21.0258777 L33.4385538,14.9588991 L45.882,23.039 L45.8829787,8.20212766 L53.1170213,8.20212766 Z M49.5,56.8297872 C41.5839186,56.8297872 35.1666667,63.3073696 35.1666667,71.2978723 C35.1666667,79.2883751 41.5839186,85.7659574 49.5,85.7659574 C57.4160814,85.7659574 63.8333333,79.2883751 63.8333333,71.2978723 C63.8333333,63.3073696 57.4160814,56.8297872 49.5,56.8297872 Z"/>
  </g>
</svg>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                required: false,
                default: '#FF9D22'
            }
        }
    }
</script>