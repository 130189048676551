<template>
    <footer>
        <div class="socials">
            <span
                v-for="(social, index) in socials"
                :key="'footerMenuitem_'+index">
                <a target="_blank" :href="social.uri">{{ social.title }}</a><span v-if="index != Object.keys(socials).length - 1"><span class="divider">&nbsp;/&nbsp;</span></span>
            </span>
        </div>
        <div class="the-end">
            <nav class="menu">
                <router-link
                        v-for="(menuItem, index) in menuItems"
                        :to="{ path: '/'+menuItem.uri }"
                        :key="'footerMenuitem_'+index"
                >{{ menuItem.title }}</router-link>
            </nav>
            <div class="made-by-g2k">Website by <a target="_blank" href="http://g2k.nl/">G2K Creative Agency</a></div>
        </div>
    </footer>
</template>

<script>
    export default {
        computed: {
            'socials': function() {
                return this.$store.state.websiteData ? this.$store.state.websiteData.footer.socials:null;
            },
            'menuItems': function() {
                return this.$store.state.websiteData ? this.$store.state.websiteData.footer.menu:null;
            }
        },
        methods: {
     
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    @import '@/scss/helper.scss';
    
    footer {
        padding: $defaultPadding;
        background-color: $black;
        display: block;
        width: 100%;
        height: 18.8rem;
        color: $white;
        @include defaultHref;
        display: flex;
        flex-flow: column;

        .socials {
            flex: 1;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            font-family: 'Oswald', sans-serif;
            @include font-med;
            text-transform: uppercase;

            .divider {
                display: none;
            }
        }

        .the-end {
            display: flex;
            flex-flow: column;
            justify-content: space-between;

            nav {
                display: flex;
                justify-content: space-between;

                a {
                    white-space: nowrap;
                }
            }

            .made-by-g2k {
                padding-top: 1rem;
                text-align: center;
            }
        }

        @include mobile {
            .socials {
                flex-flow: row;

                .divider {
                    display: inline-block;
                }
            }
        }

        @include tablet {
            .the-end {
                flex-flow: row;

                nav {
                    display: block;

                    a {
                        margin-right: 1.3em;
                    }
                }
                
                .made-by-g2k {
                    padding-top: 0;
                    text-align: left;
                }
            }
        }
    }
</style>
