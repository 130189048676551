// Import Vue
import Vue from 'vue';

// Import Vue App, routes, store
import App from './App.vue';

import router from './router/router';

import store from './store/store'
import 'es6-promise/auto'; // needed for store but also other places promises are used

// Axios (ajax)
import axios from 'axios' 
Vue.prototype.$http = axios;

// Idle timer
import IdleVue from 'idle-vue'

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1000,
  events: ['mousemove', 'keydown', 'mousedown', 'touchstart','scroll']

})

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// lazysizes
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
});