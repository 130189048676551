<template>
    <div class="page-wrapper show">
        <div class="show-intro">
            <div v-if="content.header" class="image">
                <div class="content">
                    <img :data-srcSet="content.header.image.srcSet" data-sizes="auto" class="background lazyload">
                    <div class="overlay" :class="{'start-animation': startOverlayAnimation}">
                        <inline-svg :src="content.header.overlay.url" @loaded="overlayLoaded($event)"/>
                    </div>
                </div>
            </div>
            <div class="text">
                <div class="title"><h1>{{ content.title }}</h1></div>
                <div class="dates">
                    <span @click="onDatesClick">Dates</span>
                </div>
                <div class="bookings" v-if="content.bookings">
                    Bookings: <a :href="'mailto:'+content.bookings">{{ content.bookings }}</a>
                </div> 
            </div>
        </div>

        <component 
            v-for="(component, index) in content.components" 
            :is="component.name"
            :content="component.data" 
            :show="content.id"
            :key="component.name+'_'+index"
        />
        
    </div>
</template>

<script>
    import DefaultPage from '../mixins/DefaultPage.vue';
    import InlineSvg from 'vue-inline-svg';
    
    export default {
        mixins: [DefaultPage],
        components: {
            InlineSvg
        },
        data: function() {
            return {
                startOverlayAnimation: false
            }
        },
        computed: {
            pageHasCalendar() {
                return document.getElementsByClassName('calendar')[0];
            },
            addStickers: {
               get() {
                   return this.$store.state.addStickers;
               },
               set(addStickers) {
                   this.$store.commit('setAddStickers', addStickers)
               }
           },
        },
        methods: {
            onDatesClick() {
                const el = document.getElementsByClassName('calendar')[0];
                if(el) {
                    el.scrollIntoView({behavior: "smooth", block: "center"});
                }
            },
            overlayLoaded() {
                setTimeout(() => {
                    this.startOverlayAnimation = true;
                }, 100);
            }
        },
        mounted() {
            this.addStickers = false;

            setTimeout(() => {
                this.addStickers = true;
            }, 2000);
        }

    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .page-wrapper.show {
        margin-top: 3rem;
        position: relative;

        .show-intro {
            position: relative;

            .image {
                pointer-events: none; 
                @include aspect-ratio(480, 283);

                .background {
                    @include lazyloaded;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
                
                .overlay {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    
                    svg {
                        position: absolute;
                        width: 100%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        > g {
                            opacity: 0;
                            transition: opacity 0s linear;

                            @for $i from 1 through 30 {
                                &:nth-child(#{$i}) {
                                    transition-delay: #{$i*150}ms;
                                }
                            }
                        }

                    }

                    &.start-animation {
                        opacity: 1;

                        svg {
                            > g {
                                opacity: 1;
                            }
                        }
                    }
                    
                }
            }

            .text {
                @include standardGrid;
                padding: $defaultPadding ;

                @include defaultHref;

                > div {
                    grid-column: span 4;
                }

                h1 {
                    font-family: 'Archivo', sans-serif;
                    font-weight: 400;
                    @include font-reg;
                }

                .dates {
                    span {
                        &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
</style>

