<template>
    <div class="lang-toggle">
        <a v-for="lang in availableLanguages" v-bind:key="'lang_'+lang" @click="changeLang(lang)" :class="{active: lang == currentLanguage}">{{ lang.toUpperCase() }}</a>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'LangToggle',
        computed: {
            ...mapGetters([
                'langPrefix'
            ]),
            availableLanguages: function() {
                return this.$store.state.availableLanguages;
            },
            currentLanguage: {
                get() {
                    return this.$store.state.currentLanguage;
                },
                set(language) {
                    this.$store.commit('setCurrentLanguage', language)
                }
            }
        },
        methods: {
            changeLang: function(lang) {
                if(lang != this.currentLanguage) {

                    const availableTranslations = this.$store.state.availableTranslations;

                    if(availableTranslations && availableTranslations[lang] !== undefined) {
                        this.currentLanguage = lang;

                        if(!availableTranslations[lang]) {
                            this.$router.push({ path: this.langPrefix })
                            return;
                        }

                        this.$router.push({ path: this.langPrefix+availableTranslations[lang] })
                    }
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    @import '@/scss/helper.scss';
    
    .lang-toggle {
        position: relative;
        
        a {
           display: inline-block;
        }

        a.active {
           text-decoration: underline !important;
        }

        a:first-child {
            margin-right: .2em;
        }

    }
</style>
