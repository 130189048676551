<template>
    <component v-if="content" :is="pageType" :content="content" :key="'page_'+content.id"></component>
</template>

<script>
    import Single from './Single'
    import Show from './Show'
    import ShowsOverview from './ShowsOverview'
    import PageNotFound from './PageNotFound'

    import { mapMutations } from 'vuex'
    
    export default {
        components: {
            // Layouts
            Single,
            Show,
            ShowsOverview,
            PageNotFound
        },
        data: function() {
            return {
                loading: true,
                content: {},
                pageType: 'Single'
            }
        },
        computed: {
            pageLoading: {
                get () {
                    return this.$store.state.pageLoading;
                },
                set (status) {
                    return this.$store.commit('setPageLoading', status);
                }
            }
        },
        methods: {
            ...mapMutations([
                'setAvailableTranslations',
                'setTheme'
            ]),
            loadContent: function(to) {
                this.pageLoading = true;
                
                
                return new Promise((resolve, reject) => {
                    const startTime = Date.now();

                    const urlPart1 = to ? to.params.part1 : this.$route.params.part1,
                          urlPart2 = to ? to.params.part2 : this.$route.params.part2,
                          availableLanguages = this.$store.state.availableLanguages;

                    let slug = null,
                        content = null;

                    // Check if part 1 of the url is language then set part 2 as the slug or get the homepage if part 2 is empty
                    if(availableLanguages.includes(urlPart1)) {
                        if(urlPart2) {
                            slug = urlPart2;
                        } else {
                            slug = 'home';
                        }
                    } else if(urlPart1) {
                        slug = urlPart1;
                    } else {
                        slug = 'home'
                    }

                    let currentLanguage     = this.$store.state.currentLanguage,
                        pageContentEndpoint = this.$store.state.apiEndpoints['page'].url+slug+'.json?lang='+currentLanguage;

                    this.$http.get(pageContentEndpoint, {crossdomain: true})
                        .then((result) => {
                            // Check if enough time has passed to play the loading animation
                            const endTime = new Date(),
                                    timeDiff = endTime - startTime;
               
                            const timeout = Math.max(0, 600-timeDiff);
                            
                            setTimeout(() => {
                                window.scrollTo(0,0);

                                // Set the content to the view component
                                this.setContent(result.data).then(() => {
                                    this.pageLoading = false;
                                    resolve();
                                });
                            }, timeout);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            },
            setContent: function(content) {
                if(content) {
                    return new Promise((resolve, reject) => {
                        if (content.type == 'home') {
                            this.$store.commit('setIsHome', true);
                        } else if(this.$store.state.isHome) {
                            this.$store.commit('setIsHome', false);
                        }

                        let pageType = 'PageNotFound';

                        const single = ['home','about','single','calendarOverview'];

                        if (single.indexOf(content.type) > -1) {
                            pageType = 'Single';
                        }

                        if (content.type == 'show') {
                            pageType = 'Show';
                        }

                        if (content.type == 'showsOverview') {
                            pageType = 'ShowsOverview';
                        }

                        this.pageType = pageType;
                        
                        // this.$store.state.availableTranslations = content.locales;
                        this.content = content;
                        
                        this.setAvailableTranslations(content.translations);
                        this.setMeta(content.meta);
                        this.setStyles(content.styles);
                        resolve();
                    });

                }
            },
            setStyles: function(styles) {
                const htmlEl = document.documentElement;
                
                if(!styles) return;

                if(styles.menuTheme) {
                    this.$store.commit('setMenuTheme', styles.menuTheme);
                }

                if(styles.textTheme) {
                    this.$store.commit('setTextTheme', styles.textTheme);
                    this.$store.commit('setMenuTheme', styles.textTheme);
                }

                if(styles.blockTheme) {
                    this.$store.commit('setBlockTheme', styles.blockTheme);
                }

                if(styles.backgroundColor) {
                    this.$store.commit('setBackgroundColor', styles.backgroundColor);
                    htmlEl.style.backgroundColor = styles.backgroundColor;
                } else {
                    this.$store.commit('setBackgroundColor', null);
                    htmlEl.style.backgroundColor = '';
                }
            },
            setMeta: function(meta) {
                // Set page title
                if(this.content && this.content.title) {                    
                    const baseTitle      = this.$store.state.websiteData.title,
                          titleSeperator = this.$store.state.websiteData.titleSeperator,
                          pageTitle      = this.content.customTitle ? this.content.customTitle : this.content.title;

                    if(this.content.title =='Home') {
                        document.title = `${baseTitle}`;
                    } else {
                        document.title = `${baseTitle} ${titleSeperator} ${pageTitle}`;
                    }
                    
                }

                if(meta) {
                    if(document.querySelector('meta[name="description"]')) {
                        if(meta.description) {
                            document.querySelector('meta[name="description"]').setAttribute("content", meta.description);
                        } else if(this.$store.state.websiteData && this.$store.state.websiteData.metaDescription) {
                            document.querySelector('meta[name="description"]').setAttribute("content", this.$store.state.websiteData.metaDescription);
                        }
                    }
                }
            }
        },
        created() {
            window.scrollTo(0,0);
            this.loadContent().catch((error) => {
                if(window.location.href.indexOf("404") == -1) {
                    this.$router.push({ path: '404' });
                }
            });
        },
        beforeRouteUpdate(to, from, next) {
            this.loadContent(to).then(() => {
                next();
            })
            .catch((error) => {
                if(window.location.href.indexOf("404") == -1) {
                    this.$router.push({ path: '404' });
                } else {
                     this.pageLoading = false;
                }
            });
        },
        beforeDestroy() {

        }
    }
</script>

<style lang="scss">
  
</style>