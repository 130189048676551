import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // Settings
        debug: false,
        websiteData: null,

        isHome: false,
        initializing: true,
        pageLoading: true,
        addStickers: true,

        menuTheme: null,
        textTheme: null,
        blocksTheme: null,
        backgroundColor: null,

        // Language
        defaultLanguage: 'nl',
        currentLanguage: 'nl',
        availableLanguages: null,
        availableTranslations: null,

        // API endpoints
        apiEndpoints: {
            'website': {
                url: '/api/v1/website.json'
            },
            'news': {
                url: '/api/v1/news.json'
            },
            'page': {
                url: '/api/v1/page/'
            }
        },
    },
    mutations: {
        setInitializing: function(state, status) {
            state.initializing = status;
        },
        setPageLoading: function(state, status) {
            state.pageLoading = status;
        },
        setWebsiteData: function(state, websiteData) {
            state.websiteData = websiteData;
        },
        setAvailableLanguages: function(state, arrayOfLanguages) {
            state.availableLanguages = arrayOfLanguages;
        },
        setCurrentLanguage: function(state, language) {
            state.currentLanguage = language;
        },
        setAvailableTranslations: function(state, translations) {
            state.availableTranslations = translations;
        },
        setAddStickers: function(state, addStickers) {
            state.addStickers = addStickers;
        },
        setMenuTheme: function(state, theme) {
            state.menuTheme = theme;
        },
        setTextTheme: function(state, theme) {
            state.textTheme = theme;
        },
        setBlockTheme: function(state, theme) {
            state.blocksTheme = theme;
        },
        setBackgroundColor: function(state, color) {
            state.backgroundColor = color;
        },
        setIsHome: function(state, bool) {
            state.isHome = bool;
        },
    },
    actions: {
        setLanguage ({ commit, state }, language) {
            if (state.availableLanguages.indexOf(language) > -1) {
                commit('setCurrentLanguage', language);
                return true;
            } else {
                // language does not exist / 404
                return false;
            }
        },
    },
    getters: {
        langPrefix(state) {
            if(state.currentLanguage == state.defaultLanguage) return '/';
            return '/'+state.currentLanguage+'/';
        }
    }
})
