<template>
    <div class="global-sticker-layer">
        <div class="hidden">
            <component
                v-if="sticker"
                :is="sticker.type"
                class="sticker" 
                :color="sticker.color"
            >
            </component>
        </div>
        <canvas ref="canvasEl" :style="canvasElStyle"></canvas>
    </div>
</template>

<script>
    import {debounce} from '@/js/helpers'

    // SVG's
    import alien from '@/js/svg/stickers/alien'
    import cross from '@/js/svg/stickers/cross'
    import explosion from '@/js/svg/stickers/explosion'
    import genderqueer from '@/js/svg/stickers/genderqueer'
    import globe from '@/js/svg/stickers/globe'
    import mountain from '@/js/svg/stickers/mountain'
    import peach from '@/js/svg/stickers/peach'
    import submarine from '@/js/svg/stickers/submarine'
    import teddy from '@/js/svg/stickers/teddy'

    export default {
        name: 'GlobalStickers',
        components: {
            alien,
            cross,
            explosion,
            genderqueer,
            globe,
            mountain,
            peach,
            submarine,
            teddy
        },
        data() {
            return {
                idle: false,
                sticker: null,
                stickerTimeout: null,
                
                timeInBetween: 2000, // ms
                startTimeInBetween: 2000,
                endTimeInBetween: 200,

                canvasElStyle: null,
                canvasContext: null,

                stickerTypes: [
                    'alien',
                    'cross',
                    'explosion',
                    'genderqueer',
                    'globe',
                    'mountain',
                    'peach',
                    'submarine',
                    'teddy'
                ],

                stickerColors: [
                    /* red */         "#D20000",
                    /* orange */      "#FF9D22",
                    /* yellow */      "#FFF330",
                    /* green-light */ "#65D328",
                    /* green-dark */  "#00AC62",
                    /* turquise */    "#4BD7C0",
                    /* blue */        "#4D3BFF",
                    /* purple */      "#5700BF",
                    /* pink */        "#D20073",
                ]
            };
        },
        computed: {
            addStickers: {
               get() {
                   return this.$store.state.addStickers;
               },
               set(addStickers) {
                   this.$store.commit('setAddStickers', addStickers)
               }
           },
        },
        onIdle() {
            this.idle = true;
        },
        onActive() {
            this.idle = false;

            this.removeStickers();
        },
        methods: {
            fixCanvasDpi() {
                const canvas = this.$refs['canvasEl'];
                var dpr = window.devicePixelRatio*2 || 1;
                canvas.width = window.innerWidth*dpr;
                canvas.height = window.innerHeight*dpr;
                
                var ctx = canvas.getContext('2d');
                ctx.scale(dpr, dpr);

                this.canvasContext = ctx;

                canvas.style.height = window.innerHeight+'px';
                canvas.style.width = window.innerWidth+'px';
            },
            onResize() {
                this.fixCanvasDpi();
            },
            generateSticker() {
                // Prevent double timeouts if for any reason these occur
                clearTimeout(this.stickerTimeout);

                if(this.idle && this.$store.state.addStickers && !this.$store.state.debug) {

                    const x = Math.floor(Math.random() * (window.innerWidth)+50),
                          y = Math.floor(Math.random() * (window.innerHeight));

                    this.sticker = (
                        {
                            type: this.stickerTypes[Math.floor(Math.random() * this.stickerTypes.length)],
                            color: this.stickerColors[Math.floor(Math.random() * this.stickerColors.length)],
                            rotation: Math.floor(Math.random()*100) - 50,
                            x: x,
                            y: y
                        }
                    ); 

                    this.drawSticker();

                    if(this.timeInBetween > this.endTimeInBetween) {
                        this.timeInBetween = this.timeInBetween-100;
                    }
                }

                this.stickerTimeout = setTimeout(() => {
                    this.generateSticker();
                }, this.timeInBetween);
            },
            drawSticker() {
                // get svg data
                var svg = this.$el.querySelector('svg');
                
                if(svg) {
                    const size = svg.clientWidth;
                    svg.setAttribute('height', size+'px');
                    svg.setAttribute('width', size+'px');

                    var xml = new XMLSerializer().serializeToString(svg);

                    // make it base64
                    var svg64 = btoa(xml);
                    var b64Start = 'data:image/svg+xml;base64,';

                    // prepend a "header"
                    var image64 = b64Start + svg64;

                    // set it as the source of the img element
                    var img = new Image(size*2, size*2);

                    // draw the image onto the canvas
                    img.onload = () => {
                        // save the current co-ordinate system 
                        // before we screw with it
                        this.canvasContext.save(); 

                        this.canvasContext.mozImageSmoothingEnabled = false;
                        this.canvasContext.webkitImageSmoothingEnabled = false;
                        this.canvasContext.msImageSmoothingEnabled = false;
                        this.canvasContext.imageSmoothingEnabled = false;
                    
                        // move to the middle of where we want to draw our image
                        this.canvasContext.translate(this.sticker.x, this.sticker.y);
                    
                        // rotate around that point, converting our 
                        // angle from degrees to radians 
                        this.canvasContext.rotate(this.sticker.rotation * (Math.PI/180));
                    
                        this.canvasContext.drawImage(img, -img.width, -img.height);
                    
                        // and restore the co-ords to how they were when we began
                        this.canvasContext.restore(); 


                        // this.canvasContext.drawImage(img, this.sticker.x, this.sticker.y);

                        // this.canvasContext.restore();
                    }
                    
                    img.src = image64;
                }
            },
            removeStickers() {
                // reset timing
                this.timeInBetween = this.startTimeInBetween;
                // clear canvas
                this.canvasContext.clearRect(0, 0, window.innerWidth, window.innerHeight);
            }
        },
        watch: {
            'addStickers': function(addStickers) {
                if(!addStickers) {
                    this.removeStickers();
                }
            }
        },
        mounted() {
            this.fixCanvasDpi();

            this.$nextTick(() => {
                this.generateSticker();
            })

            window.addEventListener('resize', this.onResize);
        }
    };
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .global-sticker-layer {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        z-index: 10;

        canvas {
            width: 100%;
            height: 100%;
        }

        .hidden {
            opacity: 0;

            .sticker {
                width:      10vw;
                height:     10vw;

                min-width:  60px;
                min-height: 60px;
                max-width:  125px;
                max-height: 125px;

                position: absolute;

                @each $name, $color in $colors {
                    &.#{$name} .inherit-color  {
                        fill: $color;
                    }
                }
            }
        }
    }
</style>